import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel2.css';
import useBreakpoints from './useBreakpoints';
import { Box, useTheme } from '@mui/material';
import RightSlider from 'public/assets/icons/right-slider.svg';
import LeftSlider from 'public/assets/icons/left-slider.svg';
interface CarouselProps {
  children: React.ReactNode;
}
function SampleNextArrow(props: any) {
  const {
    palette: {
      primary,
      common,
      text
    }
  }: any = useTheme();
  const {
    className,
    style,
    onClick
  } = props;
  return <Box onClick={onClick} sx={{
    position: 'absolute',
    right: '-44px',
    top: '12em',
    transform: 'translateY(-50%)',
    border: '0px solid red',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: text.primary
    },
    borderRadius: '10px',
    backgroundColor: '#94999f',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }} width={36} height={36} data-sentry-element="Box" data-sentry-component="SampleNextArrow" data-sentry-source-file="Carousel2.tsx">
      <RightSlider data-sentry-element="RightSlider" data-sentry-source-file="Carousel2.tsx" />
    </Box>;
}
function SamplePrevArrow(props: any) {
  const {
    palette: {
      primary,
      common,
      text
    }
  }: any = useTheme();
  const {
    className,
    style,
    onClick
  } = props;
  return <Box onClick={onClick} sx={{
    position: 'absolute',
    left: '-44px',
    top: '12em',
    transform: 'translateY(-50%)',
    border: '0px solid red',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: text.primary
    },
    borderRadius: '10px',
    backgroundColor: '#94999f',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }} width={36} height={36} data-sentry-element="Box" data-sentry-component="SamplePrevArrow" data-sentry-source-file="Carousel2.tsx">
      <LeftSlider data-sentry-element="LeftSlider" data-sentry-source-file="Carousel2.tsx" />
    </Box>;
}
const Carousel2: React.FC<CarouselProps> = ({
  children
}) => {
  const {
    isMd,
    isLg
  } = useBreakpoints();
  return <Box className="slider-container" style={{
    width: 'calc(100% - 100px)',
    margin: 'auto'
  }} data-sentry-element="Box" data-sentry-component="Carousel2" data-sentry-source-file="Carousel2.tsx">
      <Slider {...{
      width: '100%',
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      ...(isMd ? {
        slidesToShow: 2,
        slidesToScroll: 1
      } : {}),
      ...(isLg ? {
        slidesToShow: 4,
        slidesToScroll: 1
      } : {})
    }} data-sentry-element="Slider" data-sentry-source-file="Carousel2.tsx">
        {children}
      </Slider>
    </Box>;
};
export default Carousel2;